/* Default */

.skeletonWrapper {
    display: flex;
    background-color: rgba(0, 0, 0, .03);
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.contentSkeleton {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.imageSkeleton{
    background: rgba(0, 0, 0, .03);
    width: 100%;
}

.titleSkeleton{
    width: 100%;
    background: rgba(0, 0, 0, .03);
}

.subtitleSkeleton{
    width: 100%;
    background: rgba(0, 0, 0, .03);
}

.buttonSkeleton{
    width: 100%;
    background: rgba(0, 0, 0, .03);
}

.buttonGroup{
    display: flex;
}

.divideSkeleton{
    height: 20px;
    background: rgba(0, 0, 0, 0);
}

/* Blog */

.skeletonBlogWrapper {
    border-radius: 10px;
}

.skeletonBlogWrapper .contentSkeleton {
    margin: 15px;
    padding: 0px;
}

.skeletonBlogWrapper .imageSkeleton{
    height: 170px;
}

.skeletonBlogWrapper .subtitleSkeleton{
    height: 20px;
}

.skeletonBlogWrapper .titleSkeleton{
    height: 40px;
    margin-bottom: 15px;
}

/* Blog Detail */

.skeletonBlogDetail {
    background-color: rgba(0, 0, 0, 0);
}

.skeletonBlogDetail .contentSkeleton {
    margin-top: 15px;
    padding: 0px;
}

.skeletonBlogDetail .titleSkeleton{
    height: 40px;
    margin-bottom: 15px;
    background: rgba(0, 0, 0, .05);
}

.skeletonBlogDetail .subtitleSkeleton{
    height: 20px;
    margin-bottom: 20px;
    background: rgba(0, 0, 0, .05);
}

.skeletonBlogDetail .divideSkeleton{
    height: 20px;
    background: rgba(0, 0, 0, 0);
}

.skeletonBlogDetail .imageSkeleton{
    height: 300px;
    background: rgba(0, 0, 0, .05);
    margin-bottom: 20px;
}


/* TipsTricks */

.skeletonTipsTricks {
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.skeletonTipsTricks .contentSkeleton {
    margin-left: 20px;
    width: 400px;
    padding: 0px;
    justify-content: center;
}

.skeletonTipsTricks .imageSkeleton{
    height: 170px;
    width: 170px
}

.skeletonTipsTricks .subtitleSkeleton{
    width: 100%;
    height: 20px;
}

.skeletonTipsTricks .titleSkeleton{
    height: 40px;
    margin-bottom: 15px;
}

/* Cart */

.skeletonCartWrapper {
    border-radius: 15px;
    flex-direction: row;
    justify-content: flex-start;
}

.skeletonCartWrapper .contentSkeleton {
    padding: 30px 30px 30px 15px;
    width: 70%;
    justify-content: center;
}

.skeletonCartWrapper .imageSkeleton{
    height: 160px;
    width: 160px;
    margin: 30px 15px 30px 30px;
}

.skeletonCartWrapper .titleSkeleton{
    height: 30px;
    margin-bottom: 10px;
}

.skeletonCartWrapper .subtitleSkeleton{
    height: 20px;
    margin-bottom: 10px;
}

.skeletonCartWrapper .buttonSkeleton{
    height: 30px;
    width: 30px;
    margin-right: 15px
}

.skeletonCartWrapper .buttonGroup {
    margin-bottom: 10px;
}

/* Cart Box */

.skeletonCartBox {
    border-radius: 15px;
}

.skeletonCartBox .contentSkeleton {
    justify-content: flex-start;
    padding: 30px;
}

.skeletonCartBox .imageSkeleton{
    height: 230px;
}

.skeletonCartBox .titleSkeleton{
    height: 30px;
    margin-bottom: 10px;
}

.skeletonCartBox .subtitleSkeleton{
    height: 20px;
    margin-bottom: 10px;
}

.skeletonCartBox .buttonGroup{
    justify-content: center;
}

.skeletonCartBox .buttonSkeleton{
    height: 40px;
    width: 50%;
}

/* ProductCategory */

.skeletonProductCategory {
    width: 500px;
    flex-direction: row;
    background: none;
    justify-content: flex-start
}

.skeletonProductCategory .titleSkeleton {
    height: 40px;
    width: 100px;
    margin-right: 15px;
    background: rgba(0, 0, 0, .06);
}

/* Responsive */

@media only screen and (max-width: 600px) {
    .skeletonProductWrapper .contentSkeleton {
        margin: 20px;
        height: '160px',
    }

    .skeletonProductWrapper .imageSkeleton{
        height: 180px;
    }
    
    .skeletonBlogWrapper .imageSkeleton{
        height: 100px;
    }
}

/* Shimmer */

.shimmerWrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: loading 1.5s infinite;
}

.shimmerContent{
    background: rgba(255, 255, 255, .25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.05);
    width: 50%;
    height: 100%;
    transform: skewX(-15deg);
}

@keyframes loading {
    0% { transform: translateX(-150%) }
    30% { transform: translateX(-80%) }
    100% { transform: translateX(150%) }
}
