body {
    margin: 0;
    font-family: 'Courier Prime', Courier, monospace;
}

div.media_iframe_video .css_editable_mode_display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 2;
}

div.media_iframe_video {
    margin: 0 auto;
    text-align: center;
    position: relative;
    overflow: hidden;
    min-width: 100px;
    min-height: 500px;
}

div.media_iframe_video iframe {
    width: 100%;
    height: 500px;
}

.auth-link {
    text-decoration: none;
    color: #F7F7F7;
}

.auth-link-text-right {
    text-align: right;
}

.auth-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .media_iframe_video iframe {
        height: 400px!important;
    }
    .auth-link-text-right {
        text-align: left;
    }
}

